@import "~leanplum-lib-ui/src/styles/variables";

.login-form {
  margin: 24px auto;
  max-width: 480px;
  min-width: 360px;
  width: 100%;

  > .lp-card > .lp-card-content {
    padding-top: 16px;
  }

  > .lp-card > .lp-card-actions {
    flex-direction: row-reverse;
  }
}

.lp-login-title {
    line-height: 20px;
    height: 40px;

    > .welcome {
      display: block;
    }

    > .email {
      font-family: $font-family-circular-book;
    }
}

.forgot-password {
  text-decoration: none;
  color: $color-dark30;
  position: absolute;
  top: 50%;
  right: $spacing-large;
  margin-top: -10px;
  line-height: 20px;

  &:hover {
    color: $color-dark30;
  }
}
