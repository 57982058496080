@import '~leanplum-lib-ui/src/styles/variables';

// Common
$normal-font-family: $font-family-circular-book;
$bold-font-family: $font-family-circular-bold;

$black: $color-dark90;
$gray: $color-dark30;

$primary-color: $color-primary;
$muted-fg-color: $color-dark30;
$error-fg-color: $guide-error;

// Button
$normal-button-bg-color: $color-light10;
$normal-button-fg-color: $color-dark70;
$primary-button-bg-color: $primary-color;
$primary-button-fg-color: $color-white;

// Card
$card-bg-color: $color-white;
$card-border-color: $color-light10;
$card-fg-color: $black;

// Footer
$footer-link-fg-color: #191919;

// Copyright
$copyright-fg-color: $color-dark30;
