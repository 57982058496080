@import "./variables";

* {
  box-sizing: border-box;
}

#app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background-color: $color-light02;
  display: flex;
  flex-direction: column;
  font-family: $normal-font-family;
  font-size: 14px;
  height: 100%;
  justify-content: space-around;
  line-height: 20px;
  min-height: 100vh;
  text-align: center;
}

header,
footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  max-height: 30%;
  min-height: 10%;
  width: 100%;
}

header {
  padding: 24px 0 12px 0;
}

footer {
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-end;
  padding-top: 24px;

  ul > li > a {
    color: $footer-link-fg-color;
    font-family: $bold-font-family;
    outline: none;
    padding-bottom: 2px;
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid currentColor;
    }
  }
}

main {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  width: 100%;
}

.logo {
  display: inline-block;
  width: 170px;
}

.full-width {
  width: 100%;
}

.link-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    display: inline-block;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.copyright {
  color: $copyright-fg-color;
  font-family: $bold-font-family;
  margin: 32px auto 48px auto;
}
