$icon-size: 40px;
$item-full-height: 64px;
$locked-color: #b2b7b9;

.company-list-component {
  & > .lp-card {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    min-height: (2 * $item-full-height);
    padding-bottom: 16px;
    width: 480px;

    & > .lp-card-content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      margin: -8px;
      overflow: hidden;
      padding: 24px 0 32px 0;
    }
  }
}

.company-list {
  max-height: (5 * $item-full-height);
  position: relative;
}

.company-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px 12px 12px;
  width: 100%;

  & > .item-icon {
    background: #E1E4E5;
    border-radius: 8px;
    flex: 0 0 auto;
    height: $icon-size;
    letter-spacing: 1px;
    margin-right: 20px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: $icon-size;

    & > i {
      color: $locked-color;
      display: inline-block;
      height: 32px;
      line-height: 36px;
      width: 32px;

      & > svg {
        vertical-align: middle;
      }
    }

    & > span {
      font-weight: bold;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .item-name {
    flex: 1 1 auto;
    max-height: 3em;
    overflow: hidden;
  }
}
