@import "../../variables.scss";

.lp-card {
  background: $card-bg-color;
  border-radius: 4px;
  border: 1px solid $card-border-color;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  color: $card-fg-color;
  padding: 36px 40px 28px 40px;
  text-align: left;
}

.lp-card-heading {
  font-family: $bold-font-family;
}

.lp-card-content {
  padding: 32px 0;
}

.lp-card-actions {
  border-top: 1px solid $card-border-color;
  display: flex;
  justify-content: space-between;
  padding: 24px 0 0 0;
}
